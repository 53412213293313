import React from "react";
import { HeadingPrimaryBold, HeadingTertiaryBold } from "../styles/TextStyle";
import { ServiceList, ServiceNumList, ServiceText, ServiceTextHighlight } from "../components/ServicePageStyle";
import { Link } from "../styles/Styles";
import { Popup } from "../components/Popup";
import { ServicePageContainer } from "../components/Layout";
import PricesBusiness from "../components/Prices/PricesBusiness";

const Business = () => {
  return (
    <>
      <ServicePageContainer>
        <HeadingPrimaryBold>Услуги для юридических лиц</HeadingPrimaryBold>
        <ServiceText>
          Компания «Чисто-Тайм» работает с 2019 г. За этот период к нам обратилось более 1000 заказчиков. На данный момент мы обслуживаем 23 объекта, выполняя уборку. Мы можем с уверенностью сказать, что работающие у нас клинеры знают свое дело,
          имеют огромный опыт и могут быстро и качественно выполнить уборку в помещениях любой площади.
        </ServiceText>

        <HeadingTertiaryBold>Помимо ежедневной уборки офисов мы так же предлагаем услуги:</HeadingTertiaryBold>
        <ServiceList>
          <li>разовые генеральные уборки</li>
          <li>уборка снега и территории</li>
          <li>покос травы и газонов, спил деревьев</li>
          <li>профилактическая дезинфекция помещений</li>
          <li>химчистка офисной мебели</li>
        </ServiceList>

        <ServiceText>
          Мы так же готовы обеспечить вашу компанию канцелярскими товарами, гигиеническими принадлежностями и бытовой химией, офисной мебелью, СиЗ и прочими необходимыми товарами. Вы можете ознакомиться с полным каталогом товаров в нашем{" "}
          <Link href="https://chistotaym-torg.ru/" target="_blank" rel="noreferrer">
            интернет-магазине
          </Link>
          .
        </ServiceText>

        <HeadingTertiaryBold>Преимущества аутсорсинга клининговых услуг</HeadingTertiaryBold>
        <ServiceNumList>
          <li>
            <span>Многократное улучшение качества клининга.</span> Уборкой занимаются профессиональные клинеры, умеющие работать с современным клининговым оборудованием и знающие, как правильно очищать разные типы поверхностей.
          </li>
          <li>
            <span>Соблюдение норм санитарии и гигиены.</span> Клинеры выполняют уборку соответственно со всеми нормами санитарии и гигиены. После уборки и дезинфекции офисное помещение выглядит идеально чисто и свежо.
          </li>
          <li>
            <span>Уменьшение расходов.</span> Вам не нужно расширять свой штат и платить налоги и отчисления за дополнительных сотрудников. Вам не придется покупать дорогое оборудование, выделять место для его хранения, закупать расходные материалы и
            средства гигиены. Мешки для мусора, туалетная бумага или мыло не будут заканчиваться в неподходящий момент.
          </li>
          <li>
            <span>Оперативное выполнение задач любой сложности.</span> Нам можно поручить генеральную уборку офисных помещений, уборку после ремонтных работ, приведение прилегающей территории в порядок. Все работы будут выполнены качественно и
            быстро.
          </li>
          <li>
            <span>Демократичные расценки.</span> Благодаря низким ценам нашими услугами могут воспользоваться не только крупные фирмы, но и предприятия малого бизнеса с ограниченным бюджетом. За несколько лет работы мы выработали свои методы решения
            задач, которые показывают высокую эффективность. Стоимость наших услуг начинается всег от 3,5 руб./м2 в день за ежедневную и от 30 руб./м2 за генеральную уборку.
          </li>
        </ServiceNumList>
        <ServiceTextHighlight>Стоимость услуги зависит от площади и особенностей объекта. Для точного расчета стоимости работ требуется предварительный выезд менеджера. Выезд бесплатный.</ServiceTextHighlight>
        <PricesBusiness />
        <Popup />
      </ServicePageContainer>
    </>
  );
};

export default Business;
