import React from "react";
import styled from "styled-components";
import { Colors } from "../styles/Styles";
import home from "../assets/home.svg";
import business from "../assets/business.svg";
import laundry from "../assets/laundry.svg";
import carpets from "../assets/carpets.svg";
import cleaning from "../assets/cleaning.svg";
import sofa from "../assets/sofa.svg";
import window from "../assets/window.svg";
import disinfection from "../assets/disinfection.svg";

export const Category = ({ icon, name, link }) => {
  return (
    <CategoryBlock href={link}>
      <Icon src={icon} alt="icon" />
      <CategoryName>{name}</CategoryName>
    </CategoryBlock>
  );
};

const Service = ({ className }) => {
  return (
    <Services className={className}>
      <Category icon={cleaning} name="Уборки" link={"/cleaning"} />
      <Category icon={laundry} name="Химчистка" link={"/laundry"} />
      <Category icon={carpets} name="Чистка ковров" link={"/carpets"} />
      <Category icon={sofa} name="Чистка мебели" link={"/furniture"} />
      <Category icon={window} name="Мойка окон и балконов" link={"/windows"} />
      <Category icon={disinfection} name="Дезинфекция помещений" link={"/disinfection"} />
      <Category icon={home} name="Дополнительные услуги" link={"/additional"} />
      <Category icon={business} name="Для бизнеса" link={"/business"} />
    </Services>
  );
};

export default Service;

export const Services = styled.div`
  width: 100%;
  max-width: 1360px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 280px);
  justify-items: center;
  justify-content: center;
  gap: 32px;
  margin-bottom: 50px;
  padding: 0 24px;
`;

const CategoryBlock = styled.a`
  width: 280px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid ${Colors.PrimaryDark};
  border-radius: 8px;

  &:hover {
    background: ${Colors.Yellow};
  }
`;

const Icon = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 16px;
`;

const CategoryName = styled.h3`
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${Colors.PrimaryDark};
  text-align: center;
`;
