import React from "react";
import { HeadingPrimaryBold } from "../../styles/TextStyle";
import { ServiceText } from "../../components/ServicePageStyle";
import { ServicePageContainer } from "../../components/Layout";
import PricesToys from "../../components/Prices/PricesToys";

const LaundryToys = () => {
  return (
    <>
      <ServicePageContainer>
        <HeadingPrimaryBold>Химчистка мягких игрушек</HeadingPrimaryBold>
        <ServiceText>
          Почти у каждого из нас в доме есть мягкие игрушки. Для детей это любимый предмет ежедневных игр, а для взрослых – память о тех или иных событиях в жизни. В любом случае, они доставляют нам массу хлопот: игрушки быстро пачкаются, собирают
          пыль и не только теряют свой внешний вид, но и становятся опасными для здоровья предметами домашнего интерьера. Чистить их эффективно в домашних условиях достаточно сложно – при стирке мягкие игрушки деформируются, теряют цвет. Для того,
          чтобы вернуть любимым мягким игрушкам свежесть и чистоту, лучше обратиться в химчистку «Чисто-Тайм»!
        </ServiceText>

        <PricesToys />
      </ServicePageContainer>
    </>
  );
};

export default LaundryToys;
