import React from "react";
import { ServiceTable, ServiceTh, ServiceTd, ServiceTdCategory } from "../ServicePageStyle";

const PricesToys = () => {
  return (
    <>
      <ServiceTable>
        <thead>
          <tr>
            <ServiceTh>Материал</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTdCategory colSpan="2">Ткань, синтетика</ServiceTdCategory>
          </tr>
          <tr>
            <ServiceTd>Высота до 50 см</ServiceTd>
            <ServiceTd>от 370 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Высота до 1 м</ServiceTd>
            <ServiceTd>от 420 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Высота более 1 м</ServiceTd>
            <ServiceTd>от 520 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTdCategory colSpan="2"> Кожа, экокожа, шерсть, мех </ServiceTdCategory>
          </tr>
          <tr>
            <ServiceTd>Высота до 50 см</ServiceTd>
            <ServiceTd>от 370 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Высота до 1 м</ServiceTd>
            <ServiceTd>от 520 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>
    </>
  );
};

export default PricesToys;
