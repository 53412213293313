import React from "react";
import styled from "styled-components";
import { Colors } from "../styles/Styles";
import { Media } from "../styles/MediaQueries";

const Navigation = () => {
  return (
    <nav>
      <NavList>
        <NavItem>
          <NavLink href="/cleaning">Уборки</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/laundry">Химчистка</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/carpets">Чистка ковров</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/price">Прайс</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/contacts">Контакты</NavLink>
        </NavItem>
        <NavItem>
          <NavLinkExternal href="https://chistotaym-torg.ru/" target="_blank" rel="noopener noreferrer">
            Mагазин товаров
          </NavLinkExternal>
        </NavItem>
      </NavList>
    </nav>
  );
};

export default Navigation;

const NavList = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 32px;
  row-gap: 8px;
  flex-wrap: wrap;

  ${Media.xl} {
    column-gap: 20px;
  }

  ${Media.m} {
    flex-direction: column;
    justify-content: center;
    row-gap: 32px;
  }
`;

const NavItem = styled.li`
  margin: 0;
`;

const NavLink = styled.a`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${Colors.PrimaryDark};
  padding-bottom: 8px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: currentColor;
    transform: scaleX(0);
    transition: transform 0.5s ease;
  }

  &:hover:before {
    transform: scaleX(1);
  }

  ${Media.l} {
    font-size: 16px;
  }
`;

const NavLinkExternal = styled(NavLink)`
  color: ${Colors.SecondaryDark};
`;
