import { useWidthBreakpoint } from "../hooks/useWidthBreakpoint";

const Map = () => {
  const isMobileLayout = useWidthBreakpoint(768);

  return isMobileLayout ? (
    <iframe title="map" src="https://yandex.ru/map-widget/v1/?um=constructor%3Af4c98c99876042b2a2e3763a7bd8bfda9a67a7201367c4e2180ca57e13792c75&amp;source=constructor" width="100%" height="300" frameborder="0"></iframe>
  ) : (
    <iframe title="map" src="https://yandex.ru/map-widget/v1/?um=constructor%3Af4c98c99876042b2a2e3763a7bd8bfda9a67a7201367c4e2180ca57e13792c75&amp;source=constructor" width="100%" height="483" frameborder="0"></iframe>
  );
};

export default Map;
