import React from "react";
import styled from "styled-components";
import { ServiceTable, ServiceTh, ServiceTd, ServiceCaption } from "../ServicePageStyle";

const PricesTextile = () => {
  return (
    <>
      <ServiceTableTextile>
        <ServiceCaption>Покрывало</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Материал</ServiceTh>
            <ServiceTh>
              Стоимость <br /> Полуторспальное/двуспальное/евро
            </ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Гобеленое, жаккардовое, текстильное</ServiceTd>
            <ServiceTd>от 620/ 670 /720 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Сентипоновое</ServiceTd>
            <ServiceTd>от 720/ 900 /870 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Искусственный мех</ServiceTd>
            <ServiceTd>от 820/ 920 /1020 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTableTextile>
      <ServiceTableTextile>
        <ServiceCaption>Пледы</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Материал</ServiceTh>
            <ServiceTh>
              Стоимость <br /> Полуторспальное/двуспальное/евро
            </ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Шерстяной, полушерстяной</ServiceTd>
            <ServiceTd>от 550/ 600 /620 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Текстиль</ServiceTd>
            <ServiceTd>от 500/ 600 /700 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Искусственный мех</ServiceTd>
            <ServiceTd>от 720/ 820 /1020 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTableTextile>
      <ServiceTableTextile>
        <ServiceCaption>Одеяла</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Материал</ServiceTh>
            <ServiceTh>
              Стоимость <br /> Полуторспальное/двуспальное/евро
            </ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Шерстяное, полушерстяное, байковое (флисовое)</ServiceTd>
            <ServiceTd>от 570/ 670 /820 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Искусственный наполнитель, синтетическое покрытие</ServiceTd>
            <ServiceTd>от 770/ 820 /1020 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Искусственный наполнитель (синтепон, холофайбер), натуральное покрытие</ServiceTd>
            <ServiceTd>от 870/ 970/ 1120 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTableTextile>
      <ServiceTableTextile>
        <ServiceCaption>Текстиль</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Материал</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Постельное белье</ServiceTd>
            <ServiceTd>180 руб./ 1 кг сухого белья</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Скатерть</ServiceTd>
            <ServiceTd>от 300 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Полотенце</ServiceTd>
            <ServiceTd>от 80 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTableTextile>
    </>
  );
};

export default PricesTextile;

const ServiceTableTextile = styled(ServiceTable)`
  & td {
    width: 70%;
  }
`;
