import React from "react";
import { ServiceTable, ServiceTh, ServiceTd } from "../ServicePageStyle";

const PricesPram = () => {
  return (
    <>
      <ServiceTable>
        <thead>
          <tr>
            <ServiceTh>Вид</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Коляска «три в одном»</ServiceTd>
            <ServiceTd>от 1070 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Коляска летняя</ServiceTd>
            <ServiceTd>от 670 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Сильные загрязнения и запах</ServiceTd>
            <ServiceTd>от 800 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Манеж стандартный</ServiceTd>
            <ServiceTd>от 470 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Манеж нестандартный (большого размера, со спальным местом)</ServiceTd>
            <ServiceTd>от 670 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Автокресло</ServiceTd>
            <ServiceTd>от 470 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>
    </>
  );
};

export default PricesPram;
