import React from "react";
import { HeadingPrimaryBold, HeadingTertiaryBold } from "../styles/TextStyle";
import { ServiceList, ServiceText } from "../components/ServicePageStyle";
import { Popup } from "../components/Popup";
import { ServicePageContainer } from "../components/Layout";
import PricesDisinfection from "../components/Prices/PricesDisinfection";

const Disinfection = () => {
  return (
    <>
      <ServicePageContainer>
        <HeadingPrimaryBold>Дезинфекция и удаление запахов</HeadingPrimaryBold>
        <ServiceText>Мы проводим профилактическую дезинфекцию помещений современным оборудованием с применением сертифицированных средств. Рекомендуем совместить дезинфекцию с генеральной уборкой дома.</ServiceText>

        <HeadingTertiaryBold>Дезинфицирующие средства</HeadingTertiaryBold>
        <ServiceText>
          Для обработки всех поверхностей используются средства на основе аммониевых и перекисных соединений, молочной кислоты, третичных аминов и других активных веществ. Вся продукция сертифицирована, прошла многократные испытания, подтвердившие ее
          надежность и результативность. Эти средства безопасные в пользовании и прошли строгий контроль стандартов международного уровня.
        </ServiceText>

        <HeadingTertiaryBold>Профессиональная озонация</HeadingTertiaryBold>
        <ServiceText>Озонаторы используются в целях стерилизации, дезинфекции, дезодорации, уничтожения плесени и грибков. Озон на 51% сильнее хлора и действует в 15-20 раз быстрее.</ServiceText>
        <ServiceText>
          Озонатор очищает воздух от неприятных запахов, пылевых клещей, цветочной пыльцы, плесневых грибков, дезинфицирует и дезодорирует. Убирает затхлость! Бактерицидный эффект озонирования в течение 10 минут идентичен эффекту часа работы
          кварцевой лампы.
        </ServiceText>
        <HeadingTertiaryBold>Озонаторы избавят от любых запахов</HeadingTertiaryBold>
        <ServiceList>
          <li>запаха в квартире, после переезда</li>
          <li>запаха краски, натяжных потолков, пластиковой отделки</li>
          <li>запаха гари после пожара</li>
          <li>после аварии канализации</li>
          <li>сырости и плесени в помещении</li>
          <li>запаха рыбы и старого мяса в холодильных камерах </li>
          <li>запаха сигаретного дыма в автомобиле</li>
        </ServiceList>
        <PricesDisinfection />
        <Popup />
      </ServicePageContainer>
    </>
  );
};

export default Disinfection;
