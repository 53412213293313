import React from "react";
import { HeadingPrimaryBold, HeadingSecondary, HeadingTertiaryBold } from "../../styles/TextStyle";
import { ServiceList, ServiceText } from "../../components/ServicePageStyle";
import { Popup } from "../../components/Popup";
import { ServicePageContainer } from "../../components/Layout";
import PricesGeneralCleaning from "../../components/Prices/PricesGeneralCleaning";

const GeneralCleaning = () => {
  return (
    <>
      <ServicePageContainer>
        <HeadingPrimaryBold>Генеральная уборка квартиры</HeadingPrimaryBold>
        <HeadingSecondary>Перечень услуг, входящих в стоимость уборки</HeadingSecondary>
        <HeadingTertiaryBold>Комната</HeadingTertiaryBold>
        <ServiceList>
          <li>yдаление пыли со стен, карнизов, кондиционеров</li>
          <li>yдаление загрязнений со всех осветительных приборов</li>
          <li>Мытьё зеркал и стеклянных поверхностей</li>
          <li>Сухая и влажная уборка внешних поверхностей мебели, бытовой техники, предметов интерьера, имеющих свободный доступ</li>
          <li>Влажная уборка дверей и фурнитуры</li>
          <li>Влажная уборка отопительных приборов и подоконников</li>
          <li>Чистка мягкой мебели пылесосом</li>
          <li>Сухая чистка пылесосом ковров и ковровых покрытий</li>
          <li>Влажная уборка полов и плинтусов с помощью бактерицидных и ухаживающих средств</li>
        </ServiceList>
        <HeadingTertiaryBold>Кухня</HeadingTertiaryBold>
        <ServiceList>
          <li>Удаление загрязнений с осветительных приборов.</li>
          <li>Мытьё кафельного покрытия</li>
          <li>Мытьё зеркал и стеклянных поверхностей</li>
          <li>Удаление пыли и обезжиривание вертикальных и горизонтальных поверхностей кухонной мебели, имеющих свободный доступ</li>
          <li>Удаление загрязнений с наружной стороны вытяжки (очистка внутренних поверхностей идет дополнительной услугой)</li>
          <li>Мытьё духовой и микроволновой печи снаружи (очистка внутренних поверхностей идет дополнительной услугой)</li>
          <li>Мытьё холодильника снаружи (очистка внутренних поверхностей идет дополнительной услугой)</li>
          <li>Мойка поверхностей кухонной плиты</li>
          <li>Чистка и дезинфекция сантехники</li>
          <li>Влажная уборка и дезинфекция места для сбора мусора</li>
          <li>Влажная уборка дверей и фурнитуры</li>
          <li>Влажная уборка плинтусов с помощью бактерицидных и ухаживающих средств</li>
          <li>Чистка и дезинфекция мусорного ведра, замена мешков для мусора, вынос мусора</li>
        </ServiceList>
        <HeadingTertiaryBold>Санузел</HeadingTertiaryBold>
        <ServiceList>
          <li>Влажная уборка стен</li>
          <li>Удаление загрязнений с осветительных приборов</li>
          <li>Мытьё кафельных поверхностей</li>
          <li>Чистка и дезинфекция сантехники (смесители, рукомойники, унитазы, биде, ванна, душевая кабина)</li>
          <li>Мойка внешних поверхностей шкафчиков</li>
          <li>Влажная уборка дверей и фурнитуры</li>
          <li>Влажная уборка плинтусов и пола и их обработка бактерицидными и ухаживающими средствами</li>
        </ServiceList>
        <ServiceText>
          После генеральной уборки, мы предлагаем нашим заказчикам провести поддерживающую уборку на разовой или постоянной основах. Поддерживающая уборка включает в себя поддержание чистоты всех поверхностей, мытье полов и протирка пыли, протирание
          кухонных поверхностей, наведение порядка в ванных и санитарных комнатах.
        </ServiceText>
        <ServiceText>
          Вам нужно лишь связаться с нами по указанным на сайте номерам телефона либо заполнить форму обратной связи.
          <br /> Наш менеджер подробно расспросит Вас о деталях заказа. Будьте готовы ответить на следующие вопросы:
        </ServiceText>
        <ServiceList>
          <li>общая площадь квартиры</li>
          <li>наличие сложностей, например, труднодоступные или витражные окна</li>
          <li>необходимость выполнения дополнительных работ, таких как химчистка мебели или матраса</li>
          <li>Ваши пожелания в отношении тех или иных помещений</li>
          <li>Особенности материалов стен и полов (ламинат, паркет, мрамор, кафель, керамогранит и пр.)</li>
        </ServiceList>
        <PricesGeneralCleaning />
        <Popup />
      </ServicePageContainer>
    </>
  );
};

export default GeneralCleaning;
