import React from "react";
import { ServiceTable, ServiceTh, ServiceTd } from "../ServicePageStyle";

const PricesCarpets = () => {
  return (
    <>
      <ServiceTable>
        <thead>
          <tr>
            <ServiceTh>Тип</ServiceTh>
            <ServiceTh>Стоимость чистки</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Синтетический ковер</ServiceTd>
            <ServiceTd>от 200 руб./м2</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Длинноворсовый ковер (ворс от 20 мм)</ServiceTd>
            <ServiceTd>от 250 руб./м2</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>
    </>
  );
};

export default PricesCarpets;
