import React from "react";
import { ServiceTable, ServiceCaption, ServiceTh, ServiceTd } from "../ServicePageStyle";

const PricesAdditional = () => {
  return (
    <>
      <ServiceTable>
        <ServiceCaption>Уборка на кухне</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Услуга</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Холодильник (снаружи и внутри)</ServiceTd>
            <ServiceTd>400 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Микроволновая печь</ServiceTd>
            <ServiceTd>300 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Духовка (снаружи и внутри)</ServiceTd>
            <ServiceTd>650 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Плита</ServiceTd>
            <ServiceTd>300 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Вытяжка</ServiceTd>
            <ServiceTd>450 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Кухонный гарнитур (снаружи и внутри)</ServiceTd>
            <ServiceTd>200 руб./м2</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Кухонный фартук (обезжиривние)</ServiceTd>
            <ServiceTd> 120 руб./м2</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Вентиляционные решетки, мангал, тандыр</ServiceTd>
            <ServiceTd> 200 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>

      <ServiceTable>
        <ServiceCaption>Санузел и ванна</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Услуга</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Стиральная машинка</ServiceTd>
            <ServiceTd>150 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Дополнительная ванна</ServiceTd>
            <ServiceTd>650 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Дополнительная душевая кабина</ServiceTd>
            <ServiceTd>1200 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Дополнительный унитаз</ServiceTd>
            <ServiceTd>350 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Очистка биде/писсуара</ServiceTd>
            <ServiceTd>350 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Джакузи</ServiceTd>
            <ServiceTd>от 850 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Зеркало</ServiceTd>
            <ServiceTd>от 110 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Очистка и отбеливание швов кафельной плитки</ServiceTd>
            <ServiceTd>от 120 руб./м2</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>

      <ServiceTable>
        <ServiceCaption>Другие услуги</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Услуга</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Дверь</ServiceTd>
            <ServiceTd>100 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Батарея отопления</ServiceTd>
            <ServiceTd>250 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Решетка оконная</ServiceTd>
            <ServiceTd>100 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Мойка стен</ServiceTd>
            <ServiceTd>от 35 руб./м2</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Мойка глянцевого потолка</ServiceTd>
            <ServiceTd>80 руб./м2</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Мойка матового потолка</ServiceTd>
            <ServiceTd>50 руб./м2</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Чистка хрустальных люстр</ServiceTd>
            <ServiceTd>от 600 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Удаление плесени</ServiceTd>
            <ServiceTd>от 500 руб./м2</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Наведение порядка внутри шкафов (удаление пыли, раскладывание вещей)</ServiceTd>
            <ServiceTd>по договоренности</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Помощь при переездах</ServiceTd>
            <ServiceTd>по договоренности</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Удаление граффити с твердых поверхностей (кроме штукатурки)</ServiceTd>
            <ServiceTd>от 500 руб./м2</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>
    </>
  );
};

export default PricesAdditional;
