import React from "react";
import styled from "styled-components";
import { Colors } from "../../styles/Styles";

export const OdnoklassnikiIcon = () => {
  return (
    <Icon width="20" height="20" viewBox="0 0 24 24">
      <circle cx="12" cy="6" r="5.5" />
      <circle cx="12" cy="6" r="2.5" />
      <path d="M17.12,12.16a8.56,8.56,0,0,1-10.25,0A1.47,1.47,0,0,0,4.5,13.3a2.17,2.17,0,0,0,1,1.81,11.84,11.84,0,0,0,3,1.43,12.23,12.23,0,0,0,1.52.36L6,20.76A1.61,1.61,0,0,0,8.32,23L12,19.21,15.68,23A1.61,1.61,0,0,0,18,20.76L14,16.89a12.23,12.23,0,0,0,1.52-.36,11.84,11.84,0,0,0,3-1.43,2.17,2.17,0,0,0,1-1.81A1.47,1.47,0,0,0,17.12,12.16Z" />
    </Icon>
  );
};

const Icon = styled.svg`
  fill: none;
  stroke: ${Colors.PrimaryDark};
`;
