import React from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";
import Header from "./components/Header";
import Home from "./pages/Home";
import Cleaning from "./pages/Cleaning/Cleaning";
import GeneralCleaning from "./pages/Cleaning/GeneralCleaning";
import BabyCleaning from "./pages/Cleaning/BabyCleaning";
import IllnessCleaning from "./pages/Cleaning/IllnessCleaning";
import BuildingCleaning from "./pages/Cleaning/BuildingCleaning";
import Carpets from "./pages/Carpets";
import Furniture from "./pages/Furniture";
import Windows from "./pages/Windows";
import Disinfection from "./pages/Disinfection";
import Business from "./pages/Business";
import Additional from "./pages/Additional";
import Laundry from "./pages/Laundry/Laundry";
import LaundryClothes from "./pages/Laundry/LaundryClothes";
import LaundryToys from "./pages/Laundry/LaundryToys";
import LaundryPram from "./pages/Laundry/LaundryPram";
import LaundryAccessories from "./pages/Laundry/LaundryAccessories";
import LaundryTextile from "./pages/Laundry/LaundryTextile";
import PersonalData from "./pages/PersonalData";
import { GlobalStyle } from "./styles/GlobalStyle";
import Footer from "./components/Footer";
import Contacts from "./pages/Contacts";
import Pricing from "./pages/Pricing";

const App = () => {
  return (
    <Wrapper>
      <GlobalStyle />
      <Header />
      <Content>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/cleaning" component={Cleaning} />
            <Route exact path="/general" component={GeneralCleaning} />
            <Route exact path="/baby" component={BabyCleaning} />
            <Route exact path="/ill" component={IllnessCleaning} />
            <Route exact path="/building" component={BuildingCleaning} />
            <Route exact path="/carpets" component={Carpets} />
            <Route exact path="/furniture" component={Furniture} />
            <Route exact path="/windows" component={Windows} />
            <Route exact path="/disinfection" component={Disinfection} />
            <Route exact path="/additional" component={Additional} />
            <Route exact path="/business" component={Business} />
            <Route exate path="/laundry" component={Laundry} />
            <Route exate path="/clothes" component={LaundryClothes} />
            <Route exate path="/toys" component={LaundryToys} />
            <Route exate path="/pram" component={LaundryPram} />
            <Route exate path="/accessories" component={LaundryAccessories} />
            <Route exate path="/textile" component={LaundryTextile} />
            <Route exact path="/privacy" component={PersonalData} />
            <Route exact path="/contacts" component={Contacts} />
            <Route exact path="/price" component={Pricing} />
          </Switch>
        </BrowserRouter>
      </Content>
      <Footer />
    </Wrapper>
  );
};

export default App;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
`;
