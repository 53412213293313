import React from "react";
import { ServiceTable, ServiceTh, ServiceTd } from "../ServicePageStyle";

const PricesBuildingCleaning = () => {
  return (
    <>
      <ServiceTable>
        <thead>
          <tr>
            <ServiceTh>Услуга</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Уборка после строительства/ремонта</ServiceTd>
            <ServiceTd>от 125 руб./м2</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>
    </>
  );
};

export default PricesBuildingCleaning;
