import React from "react";
import { PageContainer } from "../../components/Layout";
import ServiceCleaning from "../../components/ServiceCleaning";
import { HeadingPrimaryBold } from "../../styles/TextStyle";

const Cleaning = () => {
  return (
    <>
      <PageContainer>
        <HeadingPrimaryBold>Типы уборок</HeadingPrimaryBold>
        <ServiceCleaning />
      </PageContainer>
    </>
  );
};

export default Cleaning;
