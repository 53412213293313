import { React, useState } from "react";
import styled from "styled-components";
import Navigation from "./Navigation";
import NavigationMobile from "./NavigationMobile";
import logo from "../assets/logo.png";
import { Colors } from "../styles/Styles";
import { Popup } from "./Popup";
import { useWidthBreakpoint } from "../hooks/useWidthBreakpoint";

import { Media } from "../styles/MediaQueries";

const Header = () => {
  const isMobileLayout = useWidthBreakpoint(768);
  const [open, setOpen] = useState(false);

  return (
    <Wrapper>
      <HeaderBlock>
        <a href="/">
          <Logo src={logo} alt="Логотип Чисто-Тайм" />
        </a>
        <HeaderNav>
          {!isMobileLayout && (
            <HeaderBtns>
              <Phone href="tel:+73822301112">+7 3822 30 11 12</Phone>
              <Popup />
            </HeaderBtns>
          )}
          {isMobileLayout ? <NavigationMobile open={open} setOpen={setOpen} /> : <Navigation />}
        </HeaderNav>
      </HeaderBlock>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.header`
  width: 100%;
  background: ${Colors.White};
  border-bottom: 1px solid ${Colors.PrimaryDark};
  position: sticky;
  top: 0;
  z-index: 200;

  ${Media.m} {
    border: none;
  }
`;

const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;
  max-width: 1360px;
  padding: 16px 24px;
  margin: 0 auto;
`;

const HeaderNav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Phone = styled.a`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${Colors.Secondary};
  margin-right: 32px;
`;

const HeaderBtns = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const Logo = styled.img`
  ${Media.m} {
    width: 150px;
  }
`;
