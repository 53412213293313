import React from "react";
import laundry from "../assets/laundry.svg";
import toy from "../assets/toy.svg";
import stroller from "../assets/stroller.svg";
import bag from "../assets/bag.svg";
import towel from "../assets/towel.svg";
import styled from "styled-components";

import { Services, Category } from "./Service";

const ServiceLaundry = () => {
  return (
    <ServicesLaundry>
      <Category icon={laundry} name="Одежда" link={"/clothes"} />
      <Category icon={toy} name="Мягкие игрушки" link={"/toys"} />
      <Category icon={bag} name="Aксессуары" link={"/accessories"} />
      <Category icon={towel} name="Текстиль" link={"/textile"} />
      <Category icon={stroller} name="Коляски" link={"/pram"} />
    </ServicesLaundry>
  );
};

export default ServiceLaundry;

const ServicesLaundry = styled(Services)`
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
`;
