import React from "react";
import { HeadingPrimaryBold } from "../../styles/TextStyle";
import { PageContainer } from "../../components/Layout";
import ServiceLaundry from "../../components/ServiceLaundry";

const Laundry = () => {
  return (
    <>
      <PageContainer>
        <HeadingPrimaryBold>Химчистка</HeadingPrimaryBold>
        <ServiceLaundry />
      </PageContainer>
    </>
  );
};

export default Laundry;
