import styled from "styled-components";

export const Colors = {
  White: "#FFFFFF",
  Black: "#000000",
  Primary: "#4ba0e8",
  PrimaryDark: "#0086c3",
  PrimaryLight: "#00abe0",
  Secondary: "#4caf50",
  SecondaryDark: "#408a42",
  SecondaryLight: "#b3e65d",
  Text: "#212121",
  LightGrey: "#f2f2f2",
  Yellow: "#fbeb58",
};

export const Link = styled.a`
  font-weight: 500;
  color: ${Colors.PrimaryDark};
`;
