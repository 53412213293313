import React from "react";
import styled from "styled-components";
import { ServiceTable, ServiceTh, ServiceTd, ServiceTdCategory } from "../ServicePageStyle";

const PricesClothes = () => {
  return (
    <>
      <ServiceTable>
        <thead>
          <tr>
            <ServiceTh>Вид</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <LaundryClothesCategory colSpan="2">Верхняя одежда</LaundryClothesCategory>
          </tr>
          <tr>
            <ServiceTd>Пуховик (пух) до 90 см</ServiceTd>
            <ServiceTd>от 900 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Пуховик (пух) от 90 см</ServiceTd>
            <ServiceTd>от 970 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Пуховик (пух) с кожаными вставками, натуральным мехом</ServiceTd>
            <ServiceTd>от 1000 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Комбинезон с утеплением из синтетического наполнителя, искусственного меха</ServiceTd>
            <ServiceTd>От 870 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Полукомбинезон, брюки с утеплением из синтетического наполнителя, искусственного меха, пуха</ServiceTd>
            <ServiceTd>От 800 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Куртка на синтепоне до 90 см</ServiceTd>
            <ServiceTd>От 800 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Куртка на синтепоне от 90 см</ServiceTd>
            <ServiceTd>От 870 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Куртка спортивная</ServiceTd>
            <ServiceTd>От 670 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Куртка утепленная</ServiceTd>
            <ServiceTd>От 770 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Куртка горнолыжная</ServiceTd>
            <ServiceTd>От 970 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Куртка джинсовая</ServiceTd>
            <ServiceTd>От 650 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Подстежка с утеплением из пуха</ServiceTd>
            <ServiceTd>От 650 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Пальто </ServiceTd>
            <ServiceTd>От 970 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Полупальто драп</ServiceTd>
            <ServiceTd>От 820 руб.</ServiceTd>
          </tr>
          <tr>
            <LaundryClothesCategory colSpan="2">Деловая одежда</LaundryClothesCategory>
          </tr>
          <tr>
            <ServiceTd>Рубашка</ServiceTd>
            <ServiceTd>От 250 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Брюки</ServiceTd>
            <ServiceTd>От 370 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Пиджак трикотаж/замшевый/комбинированный</ServiceTd>
            <ServiceTd>От 570 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Пиджак замшевый</ServiceTd>
            <ServiceTd>От 670 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Пиджак комбинированный</ServiceTd>
            <ServiceTd>От 720 руб.</ServiceTd>
          </tr>
          <tr>
            <LaundryClothesCategory colSpan="2">Торжественная и вечерняя одежда</LaundryClothesCategory>
          </tr>
          <tr>
            <ServiceTd>Костюм мужской, трикотаж "двойка"(пиджак+брюки)</ServiceTd>
            <ServiceTd>От 870 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Костюм мужской, трикотаж "тройка"(пиджак+брюки+жилет)</ServiceTd>
            <ServiceTd>От 970 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Платье свадебное, вечернее</ServiceTd>
            <ServiceTd>От 1500 руб.</ServiceTd>
          </tr>
          <tr>
            <LaundryClothesCategory colSpan="2">Повседневная одежда</LaundryClothesCategory>
          </tr>
          <tr>
            <ServiceTd>Джинсы</ServiceTd>
            <ServiceTd>От 550 руб.</ServiceTd>
          </tr>

          <tr>
            <ServiceTd>Сарафан/Платья из простой ткани с простым декором</ServiceTd>
            <ServiceTd>От 520 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Футболка</ServiceTd>
            <ServiceTd>От 200 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Шорты ткань</ServiceTd>
            <ServiceTd>От 420 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Шорты джинсовые</ServiceTd>
            <ServiceTd>От 470 руб.</ServiceTd>
          </tr>
          <tr>
            <LaundryClothesCategory colSpan="2">Форма, спецодежда</LaundryClothesCategory>
          </tr>
          <tr>
            <ServiceTd>Брюки рабочие летние</ServiceTd>
            <ServiceTd>300 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Брюки рабочие утепленные</ServiceTd>
            <ServiceTd>550 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Комбинезон рабочий летний</ServiceTd>
            <ServiceTd>400 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Комбинезон рабочий утепленный</ServiceTd>
            <ServiceTd>600 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Куртка рабочая летняя</ServiceTd>
            <ServiceTd>400 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Телогрейка, куртка рабочая утепленная</ServiceTd>
            <ServiceTd>650 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Фартук</ServiceTd>
            <ServiceTd>180 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Халат рабочий</ServiceTd>
            <ServiceTd>270 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Жилет сигнальный</ServiceTd>
            <ServiceTd>120 руб.</ServiceTd>
          </tr>
          <tr>
            <LaundryClothesCategory colSpan="2">Дополнительные услуги</LaundryClothesCategory>
          </tr>
          <tr>
            <ServiceTd>Вещи в большом количестве</ServiceTd>
            <ServiceTd>300 руб./кг</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Пропитка водоотталкивающим составом</ServiceTd>
            <ServiceTd>350 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Удаление катышков</ServiceTd>
            <ServiceTd>от 250 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>
    </>
  );
};

export default PricesClothes;

const LaundryClothesCategory = styled(ServiceTdCategory)`
  font-weight: 500;
`;
