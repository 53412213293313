import React from "react";
import { HeadingPrimaryBold } from "../../styles/TextStyle";
import PricesTextile from "../../components/Prices/PricesTextile";
import { ServicePageContainer } from "../../components/Layout";

const LaundryTextile = () => {
  return (
    <>
      <ServicePageContainer>
        <HeadingPrimaryBold>Химчистка домашнего текстиля</HeadingPrimaryBold>
        <PricesTextile />
      </ServicePageContainer>
    </>
  );
};

export default LaundryTextile;
