import React from "react";
import { ServiceTable, ServiceCaption, ServiceTh, ServiceTd } from "../ServicePageStyle";

const PricesWindows = () => {
  return (
    <>
      <ServiceTable>
        <ServiceCaption>Мойка фасадов и окон</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Тип остекления</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>2-х створчатое окно</ServiceTd>
            <ServiceTd>350 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>3-х створчатое окно</ServiceTd>
            <ServiceTd>450 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Окно более 4х створок</ServiceTd>
            <ServiceTd>от 350 руб./доп.створка</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Балконный блок (окно, балконная дверь)</ServiceTd>
            <ServiceTd>500 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Нестандартное остекление</ServiceTd>
            <ServiceTd>от 130 руб./м2</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Окно после ремонта</ServiceTd>
            <ServiceTd>от 400 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Москитная сетка</ServiceTd>
            <ServiceTd>150 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>

      <ServiceTable>
        <ServiceCaption>Балконы, лоджии с остеклением от середины</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Длина остекления</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>3 метра</ServiceTd>
            <ServiceTd>от 1200 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>4 метра</ServiceTd>
            <ServiceTd>от 1500 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>5 метров</ServiceTd>
            <ServiceTd>от 1600 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>6 метров</ServiceTd>
            <ServiceTd>от 1800 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>

      <ServiceTable>
        <ServiceCaption>Балконы, лоджии с остеклением от пола</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Длина остекления</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>3 метра</ServiceTd>
            <ServiceTd>от 1500 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>4 метра</ServiceTd>
            <ServiceTd>от 1700 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>5 метров</ServiceTd>
            <ServiceTd>от 1900 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>6 метров</ServiceTd>
            <ServiceTd>от 2300 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>
    </>
  );
};

export default PricesWindows;
