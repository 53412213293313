import styled from "styled-components";
import burger from "../../assets/burger.svg";
import close from "../../assets/close.svg";
import { bool, func } from "prop-types";

export const MenuButton = ({ open, setOpen }) => {
  return <MenuBtn onClick={() => setOpen(!open)}>{open ? <img src={close} alt="Закрыть меню" size={36} /> : <img src={burger} alt="Открыть меню" size={36} />}</MenuBtn>;
};

MenuButton.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

const MenuBtn = styled.button`
  position: absolute;
  top: 32px;
  right: 24px;
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000;

  &:focus {
    outline: none;
  }
`;
