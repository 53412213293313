import React from "react";
import styled from "styled-components";
import { Colors } from "../styles/Styles";
import time from "../assets/time.jpg";
import { Media } from "../styles/MediaQueries";

const About = () => {
  return (
    <AboutSection>
      {" "}
      <Container>
        <Information>
          <AboutList>
            <AboutFact>
              <AboutHeading>О нас</AboutHeading>
              <AboutText>
                Компания «Чисто-Тайм» - это многопрофильная компания на рынке города Томска, соединяющая в своей деятельности несколько направлений: химчистка-прачечная, клининговые услуги для юридических и физических лиц, услуги по чистке ковров и
                мягкой мебели, организация переездов, торговля товарами для уборки, канцелярскими и офисными принадлежностями.
              </AboutText>
            </AboutFact>
            <AboutFact>
              <AboutHeading>Как мы работаем?</AboutHeading>
              <AboutText>
                Мы используем современное оборудованием производства России, Турции, Франции. Все наши исполнители проходят строгий отбор, обучение и стажировку. Сотрудники строго соблюдают принципы профессиональной этики и конфиденциальности. Мы
                работаем в минимально короткие сроки, сохраняя качество услуг!
              </AboutText>
            </AboutFact>
            <AboutFact>
              <AboutHeading>Что влияет на стоимость уборки?</AboutHeading>
              <AboutText>Стоимость уборки зависит от площади квартиры/дома, типа выбранной услуги и ваших индивидуальных пожеланий дополнительных услуг.</AboutText>
            </AboutFact>
            <AboutFact>
              <AboutHeading>Как проводится уборка?</AboutHeading>
              <AboutText>
                Заранее обговорим условия уборки и оценим объём предстоящей работы. Наш менеджер обсудит все нюансы и пожеланий со стороны клиента. Клинеры подберут расходные материалы и подходящие оборудование. Перед уборкой подписывается договор, а
                по завершению работ клиент оценит качество исполнения. Оплата услуг будет только после того, как клиент приемет работу наших сотрудников.{" "}
              </AboutText>
            </AboutFact>

            <AboutFact>
              <AboutHeading>Можно ли оставить клинера одного в квартире?</AboutHeading>
              <AboutText>Конечно, вы можете спокойно оставить клинера наводить порядок и отправиться по делам. Главное чтобы вы вернулись домой к завершению уборки, чтобы принять работу. </AboutText>
            </AboutFact>
          </AboutList>
        </Information>
      </Container>
    </AboutSection>
  );
};

export default About;

const AboutSection = styled.section`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 32px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${time});
    background: url(/static/media/time.cc92e47e.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: opacity(0.5);
    z-index: -1;
  }
`;

const Container = styled.div`
  max-width: 1360px;
  margin: 0 auto;
`;

const Information = styled.div`
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  padding: 24px;

  ${Media.m} {
    padding: 16px;
  }
`;

const AboutList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const AboutFact = styled.li`
  margin-bottom: 16px;

  ${Media.m} {
    margin-bottom: 12px;
  }
`;

const AboutHeading = styled.h3`
  font-size: 20px;
  font-weight: 500;
  color: ${Colors.PrimaryDark};
  margin-bottom: 8px;

  ${Media.m} {
    font-size: 18px;
  }
`;

const AboutText = styled.p`
  font-size: 18px;
  line-height: 1.5;
  color: ${Colors.Text};

  ${Media.m} {
    font-size: 16px;
  }
`;
