import React from "react";
import { HeadingPrimaryBold } from "../styles/TextStyle";
import { ServiceText, Wrapper } from "../components/ServicePageStyle";
import { Popup } from "../components/Popup";
import { ServicePageContainer } from "../components/Layout";
import PricesWindows from "../components/Prices/PricesWindows";

const Windows = () => {
  return (
    <>
      <ServicePageContainer>
        <HeadingPrimaryBold>Мойка окон, балконов и лоджий</HeadingPrimaryBold>
        <ServiceText>Предоставляем услуги мойки окон, застекленных лоджий, витражных фасадов.</ServiceText>
        <ServiceText>Помимо очищения стекол, наши мастера отмывают рамы и фурнитуру, москитные сетки. Особое внимание уделяется чистке резиновых уплотнителей. Чистка откосов и отливов обязательно входят в процесс мытья окон.</ServiceText>
        <Wrapper>
          <PricesWindows />
          <Popup />
        </Wrapper>
      </ServicePageContainer>
    </>
  );
};

export default Windows;
