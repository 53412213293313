import React from "react";
import { HeadingPrimaryBold } from "../../styles/TextStyle";
import { ServicePageContainer } from "../../components/Layout";
import PricesAccessories from "../../components/Prices/PricesAccessories";

const LaundryAccessories = () => {
  return (
    <>
      <ServicePageContainer>
        <HeadingPrimaryBold>Химчистка aксессуаров</HeadingPrimaryBold>
        <PricesAccessories />
      </ServicePageContainer>
    </>
  );
};

export default LaundryAccessories;
