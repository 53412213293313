import styled from "styled-components";
import { Colors } from "../styles/Styles";
import { Media } from "../styles/MediaQueries";

export const ServicePageContainer = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 50px auto;
`;

export const ServiceList = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  font-size: 16px;
  margin-bottom: 24px;

  ${Media.m} {
    font-size: 14px;
    margin-bottom: 16px;
  }

  & li {
    margin-bottom: 8px;
    text-transform: lowercase;

    ${Media.m} {
      margin-bottom: 6px;
    }
  }

  & li::marker {
    color: ${Colors.PrimaryDark};
  }
`;

export const ServiceNumList = styled.ol`
  list-style-type: decimal;
  list-style-position: inside;
  font-size: 16px;
  margin-bottom: 24px;

  ${Media.m} {
    font-size: 14px;
    margin-bottom: 16px;
  }

  & li {
    margin-bottom: 8px;
    ${Media.m} {
      margin-bottom: 6px;
    }
  }

  & li::marker,
  & li > span {
    color: ${Colors.PrimaryDark};
    font-weight: 500;
  }

  & li > p {
    margin-bottom: 8px;

    ${Media.m} {
      margin-bottom: 6px;
    }
  }
`;

export const ServiceText = styled.p`
  width: 100%;
  line-height: 1.5;
  margin-bottom: 16px;
  text-align: justify;

  ${Media.m} {
    font-size: 14px;
  }
`;

export const ServiceTextHighlight = styled(ServiceText)`
  color: ${Colors.SecondaryDark};
  font-weight: 500;
`;

export const ServiceTable = styled.table`
  max-width: 1000px;
  width: 100%;
  align-self: center;
  margin-bottom: 32px;

  ${Media.m} {
    font-size: 14px;
    margin-bottom: 24px;
  }
`;

export const ServiceCaption = styled.caption`
  font-size: 18px;
  font-weight: 600;
  color: ${Colors.PrimaryDark};
  margin-bottom: 16px;

  ${Media.m} {
    font-size: 16px;
  }
`;

export const ServiceTh = styled.th`
  font-size: 18px;
  background-color: ${Colors.Secondary};
  color: ${Colors.White};
  padding: 8px;
  border: 1px solid ${Colors.Secondary};

  ${Media.m} {
    font-size: 16px;
  }
`;

export const ServiceTd = styled.td`
  padding: 8px;
  border: 1px solid ${Colors.Secondary};
`;

export const ServiceTdCategory = styled(ServiceTd)`
  background-color: ${Colors.LightGrey};
`;

export const ServiceTdCenter = styled(ServiceTd)`
  text-align: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
`;
