import React from "react";
import { ServiceTable, ServiceTh, ServiceTd } from "../ServicePageStyle";

const PricesDisinfection = () => {
  return (
    <>
      <ServiceTable>
        <thead>
          <tr>
            <ServiceTh>Услуга</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Дезинфекция помещения</ServiceTd>
            <ServiceTd>от 150 руб./м2</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Удаление запаха озонатором (до 24 м2)</ServiceTd>
            <ServiceTd>1200 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Удаление запаха озонатором (до 50 м2)</ServiceTd>
            <ServiceTd>1800 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Удаление запаха озонатором (более 50 м2)</ServiceTd>
            <ServiceTd>от 2400 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>
    </>
  );
};

export default PricesDisinfection;
