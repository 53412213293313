import React from "react";
import styled from "styled-components";
import glove from "../assets/glove.jpg";
import Service from "../components/Service";
import About from "../components/About";
import { HeadingHero, HeadingPrimary } from "../styles/TextStyle";
import { ContainerWrapper } from "../components/Layout";
import { Media } from "../styles/MediaQueries";

const Home = () => {
  return (
    <>
      <ContainerWrapper>
        <HeroBlock>
          <HeadingHero>Доверьте нам чистоту и порядок</HeadingHero>
          <HeadingPrimary>Все виды профессиональных услуг по уборке и химчистке </HeadingPrimary>
        </HeroBlock>

        <Service />
        <GloveImg src={glove} />
      </ContainerWrapper>
      <About />
    </>
  );
};

export default Home;

const GloveImg = styled.img`
  content: "";
  position: absolute;
  right: 0;
  top: 200px;
  width: 50%;
  max-width: 800px;
  max-height: 50vh;
  z-index: -1;

  ${Media.l} {
    top: 180px;
  }

  ${Media.m} {
    top: 150px;
  }

  ${Media.s} {
    display: none;
  }
`;

const HeroBlock = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin: 100px 0 100px 50px;

  ${Media.l} {
    margin: 50px 0 50px 32px;
  }

  ${Media.s} {
    width: 100%;
    margin: 0;
  }
`;
