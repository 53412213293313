import React from "react";
import { HeadingPrimaryBold, HeadingTertiaryBold } from "../../styles/TextStyle";
import { ServiceText, ServiceTextHighlight } from "../../components/ServicePageStyle";
import { ServicePageContainer } from "../../components/Layout";
import PricesClothes from "../../components/Prices/PricesClothes";

const LaundryClothes = () => {
  return (
    <>
      <ServicePageContainer>
        <HeadingPrimaryBold>Химчистка одежды</HeadingPrimaryBold>
        <HeadingTertiaryBold>Верхняя одежда</HeadingTertiaryBold>
        <ServiceText>
          В условиях российского климата наши вещи принимают всю тяжесть погодных условий, защищая нас от холода, снега, дождя, грязи и ветра. Должны и мы позаботиться о них в ответ! Сдавайте верхнюю одежду в химчистку не только по мере загрязнения,
          но и по окончанию каждого сезона. Красиво – значит чисто.
        </ServiceText>

        <HeadingTertiaryBold>Деловая одежда</HeadingTertiaryBold>
        <ServiceText>
          Требования дресс-кода – это не только стиль, но и безупречная чистота одежды. Чаще всего производители не рекомендуют стирать деловые костюмы дома. И действительно: закинуть пиджак в домашнюю стиральную машину – все равно, что отнести его
          на помойку. Дорогие и специфические ткани, из которых шьют офисную одежду, очень чувствительны даже к мелким ошибкам. Поэтому – вариант один: только профессиональный уход! Специалисты «Чисто-Тайм» обеспечат Вашему наряду самое деликатное
          обращение и обработку на профессиональном оборудовании с использованием лучших препаратов европейского производства.
        </ServiceText>
        <HeadingTertiaryBold>Торжественная и вечерняя одежда</HeadingTertiaryBold>
        <ServiceText>
          Самые яркие жизненные события сопровождаются высокими рисками для одежды. Одна неприятная неожиданность – и на видном месте красуется пятно от вина или пищи. Попытка самостоятельной чистки дорогого платья или костюма может не только
          закрепить пятно на ткани, но и окончательно испортить наряд. В «Чисто-Тайме» это исключено: обработка происходит на профессиональном оборудовании с множеством настроек и режимов. То есть, загрязнение получит ровно то количество препарата,
          которое необходимо для его полного удаления, а значит, на деликатную ткань гарантировано максимально бережное воздействие. Специалисты нашей химчистки прекрасно знают, как обрабатывать торжественную одежду, и умеют возвращать изделию его
          первозданную чистоту.
        </ServiceText>
        <HeadingTertiaryBold>Повседневная одежда</HeadingTertiaryBold>
        <ServiceText>
          Одежда, которой вы пользуетесь каждый день, более прочих предметов гардероба нуждается в присмотре профессионалов. То, что чаще носится, должно и чаще чиститься! Подбирать для повседневной одежды правильные программы обработки – это задача
          профессионалов. А вы просто получаете готовые чистые и аккуратные вещи, плюс бонус: идеальное глажение. Попробовав хоть раз сдать вещи в «Чисто-тайм», вы навсегда расхотите тратить свои силы на стирку и глажку дома.
        </ServiceText>
        <HeadingTertiaryBold>Форма, спецодежда</HeadingTertiaryBold>
        <ServiceText>
          Опрятность форменной одежды – это тот случай, когда химическая чистка является единственно возможной. Только профессионалы могут сохранить форму спецодежды и избавиться от разного рода пятен. «Диана» оснащена профессиональным оборудованием
          и препаратами лучших европейских производителей. Если добавить многолетний опыт и высокую квалификацию технологов – получаются все условия для идеального результата.
        </ServiceText>
        <HeadingTertiaryBold>Детская одежда</HeadingTertiaryBold>
        <ServiceText>
          Детская одежда быстро загрязняется. Малыши еще только учатся быть аккуратными, поэтому после еды, активных игр или прогулок на свежем воздухе на красивых и ярких нарядах остаются пятна, разводы и потеки. Не хочется переводить новые джинсы
          или курточку в разряд домашнего гардероба? С учетом того, что качественная одежда стоит недешево, уход за ней следует поручить специалистам. В химчистке «Чисто-Тайм» для этой цели располагают полным арсеналом безопасных для здоровья, и в то
          же время эффективных средств для устранения пятен любой сложности и давности. Для детской одежды применяются щадящие гипоаллергенные формулы и методы, в частности, аквачистка. Мы приведем в порядок как летний, так и зимний гардероб ребенка
          – пуховики, шапки, рукавички и другие изделия, которые не рекомендовано стирать в обычной машинке.
        </ServiceText>
        <ServiceTextHighlight>Химчистка детской одежды - 50% от цены на весь перечень взрослой одежды. Скидка не распространяется на позиции прайса, в названии которых есть слово "детское" или "аксессуары".</ServiceTextHighlight>
        <PricesClothes />
      </ServicePageContainer>
    </>
  );
};

export default LaundryClothes;
