import React from "react";
import { HeadingPrimaryBold, HeadingTertiaryBold } from "../styles/TextStyle";
import { ServiceList, ServiceText, ServiceTextHighlight } from "../components/ServicePageStyle";
import { Popup } from "../components/Popup";
import { ServicePageContainer } from "../components/Layout";
import PricesCarpets from "../components/Prices/PricesCarpets";

const Carpets = () => {
  return (
    <>
      <ServicePageContainer>
        <HeadingPrimaryBold>Чистка ковров и ковролина</HeadingPrimaryBold>
        <ServiceText>Мы оказываем услуги чистки ковров в специализированном цехе. Вы можете самостоятельно сдать ковер в приемный пункт или заказать услугу доставки – тогда ваш ковер заберут из дома и привезут уже сухим и чистым.</ServiceText>
        <HeadingTertiaryBold>Стоимость чистки ковра зависит от:</HeadingTertiaryBold>
        <ServiceList>
          <li>Тип волокна коврового покрытия (натуральное/искуственное/смешанное)</li>
          <li>Тип подложки и способ изготовления ковра</li>
          <li>Степень загрязнения и заставленности помещения</li>
          <li>Наличие пятен и их размеры</li>
        </ServiceList>
        <ServiceTextHighlight>Стоимость чистки наш менеджер определит после уточнения параметров ковра.</ServiceTextHighlight>
        <ServiceTextHighlight>Доставка ковра в черте города бесплатна при заказе от 1000 руб.</ServiceTextHighlight>
        <PricesCarpets />
        <Popup />
      </ServicePageContainer>
    </>
  );
};

export default Carpets;
