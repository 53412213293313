import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../../styles/Styles";
import { Media } from "../../styles/MediaQueries";

export const BackButton = () => {
  const history = useHistory();

  return (
    <BackBtn onClick={() => history.goBack()}>
      <IconBack /> Назад
    </BackBtn>
  );
};

const IconBack = () => {
  return (
    <svg width="24" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.1,23a1,1,0,0,0,0-1.41L5.5,17H29.05a1,1,0,0,0,0-2H5.53l4.57-4.57A1,1,0,0,0,8.68,9L2.32,15.37a.9.9,0,0,0,0,1.27L8.68,23A1,1,0,0,0,10.1,23Z" fill="currentColor" />
    </svg>
  );
};

const BackBtn = styled.button`
  font-size: 18px;
  line-height: 20px;
  color: ${Colors.Secondary};
  display: flex;
  align-items: center;
  align-self: flex-start;
  column-gap: 8px;
  border: none;
  padding: 0;

  ${Media.m} {
    font-size: 16px;
  }

  &:hover {
    color: ${Colors.SecondaryDark};
  }
`;
