import React from "react";
import { HeadingPrimaryBold, HeadingTertiaryBold } from "../../styles/TextStyle";
import { ServiceList, ServiceText } from "../../components/ServicePageStyle";
import { Popup } from "../../components/Popup";
import { ServicePageContainer } from "../../components/Layout";
import PricesIllnessCleaning from "../../components/Prices/PricesIllnessCleaning";

const IllnessCleaning = () => {
  return (
    <>
      <ServicePageContainer>
        <HeadingPrimaryBold>Уборка после болезни/смерти человека</HeadingPrimaryBold>
        <ServiceText>
          Смерть близкого человека опустошает, загоняет в угол и отнимает возможность мыслить здраво. Сваливается огромное количество дел, связанных с организацией похорон и поминок. Уборка квартиры после смерти человека - важная часть траурных
          мероприятий.
        </ServiceText>
        <ServiceText>Тело умершего в процессе разложения выделяет трупный яд и ядовитый газ, который распространяется и пропитывает все помещение. Особенно быстро этот процесс происходит в теплое время года.</ServiceText>
        <ServiceText>Не проведенная вовремя уборка с использованием специальных дезинфицирующих и моющих средств может затруднить положение хозяев квартиры и угрожать их здоровью.</ServiceText>
        <HeadingTertiaryBold>Дезинфекция после смерти человека включает в себя ряд обязательных действий:</HeadingTertiaryBold>
        <ServiceList>
          <li>очищение всех поверхностей профессиональными средствами</li>
          <li>устранение очага специфического трупного запаха профессиональным оборудованием</li>
          <li>очистка мебели</li>
          <li>очистка ковровых покрытий</li>
          <li>вынос мусора и предметов, пропитанных продуктами разложения</li>
          <li>дезодорация помещения при необходимости</li>
        </ServiceList>
        <ServiceText>Эти же услуги включаются в экстремальные уборки после ассоциальных личностей, после людей с синдромом Диогена (неряшливость, мусор в доме), после больных тяжелыми инфекционными заболеваниями.</ServiceText>
        <PricesIllnessCleaning />
        <Popup />
      </ServicePageContainer>
    </>
  );
};

export default IllnessCleaning;
