import { useEffect, useState } from "react";

export const useWidthBreakpoint = width => {
  const [breakpoint, setBreakpoint] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 0) {
        if (window.innerWidth <= width && !breakpoint) {
          setBreakpoint(true);
        }
        if (window.innerWidth > width && breakpoint) {
          setBreakpoint(false);
        }
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width, breakpoint]);
  return breakpoint;
};
