import styled from "styled-components";
import { BackButton } from "../components/Buttons/BackButton";
import { Media } from "../styles/MediaQueries";

export const PageContainer = ({ children }) => {
  return (
    <ContainerWrapper>
      <BackButton />
      {children}
    </ContainerWrapper>
  );
};

export const ContainerWrapper = styled.div`
  width: 100%;
  max-width: 1360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px auto;
  padding: 0 24px;

  ${Media.m} {
    margin: 24px auto;
    padding: 0 16px;
  }
`;

export const ServicePageContainer = ({ children }) => {
  return (
    <ContainerWrapper>
      <BackButton />
      <ServiceContainerWrapper>{children}</ServiceContainerWrapper>
    </ContainerWrapper>
  );
};

export const ServiceContainerWrapper = styled(ContainerWrapper)`
  max-width: 1200px;
  align-items: flex-start;
  margin: 0;

  ${Media.m} {
    margin: 24px auto;
  }
`;
