import React from "react";
import { HeadingPrimaryBold } from "../styles/TextStyle";
import { Link } from "../styles/Styles";
import { ServiceText, Wrapper } from "../components/ServicePageStyle";
import { Popup } from "../components/Popup";
import { ServicePageContainer } from "../components/Layout";
import PricesAdditional from "../components/Prices/PricesAdditional";

const Additional = () => {
  return (
    <>
      <ServicePageContainer>
        <HeadingPrimaryBold>Дополнительные услуги</HeadingPrimaryBold>
        <Wrapper>
          <ServiceText>
            Мы готовы обеспечить вашу компанию канцелярскими товарами, гигиеническими принадлежностями и бытовой химией, офисной мебелью, СиЗ и прочими необходимыми товарами. Вы можете ознакомиться с полным каталогом товаров в нашем{" "}
            <Link href="https://chistotaym-torg.ru/" target="_blank" rel="noreferrer">
              интернет-магазине
            </Link>
            .
          </ServiceText>
          <PricesAdditional />

          <Popup />
        </Wrapper>
      </ServicePageContainer>
    </>
  );
};

export default Additional;
