import React from "react";
import { ServiceTable, ServiceTh, ServiceTd } from "../ServicePageStyle";

const PricesBusiness = () => {
  return (
    <>
      <ServiceTable>
        <thead>
          <tr>
            <ServiceTh>Услуга</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Генеральная уборка офиса (до 200 м2)</ServiceTd>
            <ServiceTd>9500 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Ежедневная поддерживающая уборка (до 200 м2)</ServiceTd>
            <ServiceTd>25000 руб./месяц</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Генеральная уборка столовой и зоны приготовления пищи (до 100 м2)</ServiceTd>
            <ServiceTd>9000 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Дезинфекция помещения (до 100 м2)</ServiceTd>
            <ServiceTd>14000 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Химчистка офисного стула</ServiceTd>
            <ServiceTd>120 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Химчистка кресла руководителя</ServiceTd>
            <ServiceTd>380 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Химчистка портьер и штор (включая монтаж/демонтаж)</ServiceTd>
            <ServiceTd>450 руб./кг</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>
    </>
  );
};

export default PricesBusiness;
