import React from "react";
import { HeadingPrimaryBold } from "../styles/TextStyle";
import { ServiceText, Wrapper } from "../components/ServicePageStyle";
import { Popup } from "../components/Popup";
import { ServicePageContainer } from "../components/Layout";
import PricesFurniture from "../components/Prices/PricesFurniture";

const Furniture = () => {
  return (
    <>
      <ServicePageContainer>
        <HeadingPrimaryBold>Чистка мягкой мебели и матрасов</HeadingPrimaryBold>
        <ServiceText>
          Химическая чистка диванов, мягкой мебели и матрасов проводится высокопрофессиональным современным оборудованием, способным на дому у заказчика вывести большинство пятен. После проведенной чистки мы даем рекомендации по уходу именно за вашей
          мебелью.
        </ServiceText>
        <Wrapper>
          <PricesFurniture />
          <Popup />
        </Wrapper>
      </ServicePageContainer>
    </>
  );
};

export default Furniture;
