import styled from "styled-components";
import { Colors } from "./Styles";
import { Media } from "./MediaQueries";

export const HeadingHero = styled.h1`
  font-size: 45px;
  line-height: 1.2;
  font-weight: 600;
  color: ${Colors.PrimaryDark};
  margin-bottom: 32px;

  ${Media.m} {
    font-size: 38px;
  }

  ${Media.s} {
    font-size: 30px;
    margin-bottom: 16px;
    text-align: center;
  }
`;

export const HeadingPrimary = styled.h2`
  font-size: 26px;
  line-height: 1.5;
  font-weight: 500;
  color: ${Colors.PrimaryDark};
  margin-bottom: 32px;

  ${Media.m} {
    font-size: 20px;
    margin-bottom: 24px;
  }

  ${Media.s} {
    font-size: 18px;
  }
`;

export const HeadingPrimaryBold = styled(HeadingPrimary)`
  font-weight: 600;
  margin-bottom: 40px;
  align-self: center;
  ${Media.l} {
    margin-bottom: 32px;
  }
  ${Media.m} {
    margin-bottom: 24px;
    text-align: center;
  }
`;

export const HeadingSecondary = styled.h3`
  font-size: 20px;
  font-weight: 500;
  color: ${Colors.PrimaryDark};
  margin-bottom: 24px;

  ${Media.m} {
    font-size: 16px;
    margin-bottom: 16px;
    text-align: center;
  }
`;

export const HeadingTertiaryBold = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: ${Colors.PrimaryDark};
  margin-bottom: 16px;

  ${Media.m} {
    font-size: 16px;
  }
`;
