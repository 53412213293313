import React from "react";
import { ServiceTable, ServiceCaption, ServiceTh, ServiceTd, ServiceTdCategory } from "../ServicePageStyle";

const PricesFurniture = () => {
  return (
    <>
      <ServiceTable>
        <ServiceCaption>Чистка дивана</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Тип дивана</ServiceTh>
            <ServiceTh>Вид</ServiceTh>
            <ServiceTh colSpan="2">Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTdCategory colSpan="2" />
            <ServiceTdCategory>Текстиль</ServiceTdCategory>
            <ServiceTdCategory>Кожа</ServiceTdCategory>
          </tr>
          <tr>
            <ServiceTd>2-х местный</ServiceTd>
            <ServiceTd>Клик-кляк, чебурашка (выезжает вперед)</ServiceTd>
            <ServiceTd>1600 руб.</ServiceTd>
            <ServiceTd>1600 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>2-х местный</ServiceTd>
            <ServiceTd>Диван-книжка</ServiceTd>
            <ServiceTd>1800 руб.</ServiceTd>
            <ServiceTd>2800 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>3-х местный</ServiceTd>
            <ServiceTd>Угловой (угол не выдвигается)</ServiceTd>
            <ServiceTd>2300 руб.</ServiceTd>
            <ServiceTd>4000 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>3-х местный</ServiceTd>
            <ServiceTd>Прямой/угловой</ServiceTd>
            <ServiceTd>3000 руб.</ServiceTd>
            <ServiceTd>4800 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd colSpan="2">Дополнительное посадочное место</ServiceTd>
            <ServiceTd>500 руб.</ServiceTd>
            <ServiceTd>1000 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>

      <ServiceTable>
        <ServiceCaption>Чистка кресла/стула</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Тип</ServiceTh>
            <ServiceTh colSpan="2">Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTdCategory />
            <ServiceTdCategory>Текстиль</ServiceTdCategory>
            <ServiceTdCategory>Кожа</ServiceTdCategory>
          </tr>
          <tr>
            <ServiceTd>Кресло</ServiceTd>
            <ServiceTd>800 руб.</ServiceTd>
            <ServiceTd>1300 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Стул без спинки</ServiceTd>
            <ServiceTd>250 руб.</ServiceTd>
            <ServiceTd>350 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Стул со спинкой</ServiceTd>
            <ServiceTd>350 руб.</ServiceTd>
            <ServiceTd>500 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>

      <ServiceTable>
        <ServiceCaption>Чистка матраса</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Тип матраса</ServiceTh>
            <ServiceTh colSpan="2">Стоимость</ServiceTh>
          </tr>
          <tr>
            <ServiceTdCategory>Химчистка</ServiceTdCategory>
            <ServiceTdCategory>Одна сторона</ServiceTdCategory>
            <ServiceTdCategory>Две стороны</ServiceTdCategory>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Односпальный</ServiceTd>
            <ServiceTd>900 руб.</ServiceTd>
            <ServiceTd>1800 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Двухспальный</ServiceTd>
            <ServiceTd>1100 руб.</ServiceTd>
            <ServiceTd>2100 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTdCategory>Экочистка (чистка паром)</ServiceTdCategory>
            <ServiceTdCategory>Одна сторона</ServiceTdCategory>
            <ServiceTdCategory>Две стороны</ServiceTdCategory>
          </tr>
          <tr>
            <ServiceTd>Односпальный</ServiceTd>
            <ServiceTd colSpan="2">1500 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Двухспальный</ServiceTd>
            <ServiceTd colSpan="2">2800 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTdCategory>Дезодорация (устранение запаха)</ServiceTdCategory>
            <ServiceTdCategory>Одна сторона</ServiceTdCategory>
            <ServiceTdCategory>Две стороны</ServiceTdCategory>
          </tr>
          <tr>
            <ServiceTd>В зависимости от размера</ServiceTd>
            <ServiceTd>800 руб.</ServiceTd>
            <ServiceTd>1000 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>
    </>
  );
};

export default PricesFurniture;
