import React from "react";
import styled from "styled-components";
import { Colors } from "../styles/Styles";
import { VKIcon } from "./Icons/VKIcon";
import { OdnoklassnikiIcon } from "./Icons/OdnoklassnikiIcon";
// import { FacebookIcon } from "./Icons/FacebookIcon";
// import { InstaIcon } from "./Icons/InstaIcon";
import { Media } from "../styles/MediaQueries";

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Socials>
          <Link href="https://vk.com/public186306847" target="_blank" rel="noopener noreferrer">
            <VKIcon />
          </Link>
          <Link href="https://ok.ru/profile/574517869676" target="_blank" rel="noopener noreferrer">
            <OdnoklassnikiIcon />
          </Link>
          {/* <Link href="https://www.facebook.com/groups/2598541520198252/?ref=bookmarks" target="_blank" rel="noopener noreferrer">
            <FacebookIcon />
          </Link>
          <Link href="https://www.instagram.com/chisto_time_tomsk/?hl=ru" target="_blank" rel="noopener noreferrer">
            <InstaIcon />
          </Link> */}
        </Socials>

        <Address>​© Чисто-Тайм, ул. Киевская 28Б</Address>
      </Container>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.footer`
  width: 100%;
  flex-shrink: 0;
  padding: 16px 24px;
  background: ${Colors.LightGrey};

  ${Media.m} {
    padding: 16px;
  }
`;

const Container = styled.div`
  max-width: 1360px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Media.m} {
    column-gap: 16px;
  }
`;

const Address = styled.p`
  font-size: 16px;
  color: ${Colors.PrimaryDark};
  flex: 1;
  text-align: right;

  ${Media.m} {
    width: 50%;
    font-size: 14px;
  }
`;

const Socials = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;

  ${Media.m} {
    justify-content: center;
    gap: 16px;
  }
`;

const Link = styled.a`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${Colors.PrimaryDark};

  &:hover {
    background: ${Colors.Yellow};
  }
`;
