import React from "react";
import { HeadingPrimaryBold, HeadingTertiaryBold } from "../../styles/TextStyle";
import { ServiceList, ServiceTextHighlight } from "../../components/ServicePageStyle";
import { Popup } from "../../components/Popup";
import { ServicePageContainer } from "../../components/Layout";
import PricesBuildingCleaning from "../../components/Prices/PricesBuildingCleaning";

const BuildingCleaning = () => {
  return (
    <ServicePageContainer>
      <HeadingPrimaryBold>Уборка после строительства и ремонта</HeadingPrimaryBold>
      <HeadingTertiaryBold>Перечень услуг, входящих в стоимость уборки</HeadingTertiaryBold>
      <ServiceList>
        <li>удаление строительной пыли и загрязнений со всех поверхностей и окон</li>
        <li>влажная уборка поверхностей</li>
        <li>вынос мелкого мусора, остатков стройматериалов (до 10 кг)</li>
      </ServiceList>
      <HeadingTertiaryBold>Стоимость уборки зависит от:</HeadingTertiaryBold>
      <ServiceList>
        <li>размера помещения</li>
        <li>степени загрязнения</li>
        <li>наличия нестандартного остекления</li>
        <li>доступность к источникам водо- и электроснабжения</li>
        <li>высотность работ и количества заставленной площади</li>
      </ServiceList>
      <ServiceTextHighlight>Для точного расчета стоимости работ требуется предварительный выезд менеджера.</ServiceTextHighlight>
      <PricesBuildingCleaning />
      <Popup />
    </ServicePageContainer>
  );
};

export default BuildingCleaning;
