import React from "react";
import styled from "styled-components";
import * as Accordion from "@radix-ui/react-accordion";
import arrow from "../assets/arrow.svg";
import { Colors } from "../styles/Styles";
import { HeadingTertiaryBold } from "../styles/TextStyle";
import PricesClothes from "../components/Prices/PricesClothes";
import PricesToys from "../components/Prices/PricesToys";
import PricesAccessories from "../components/Prices/PricesAccessories";
import PricesTextile from "../components/Prices/PricesTextile";
import PricesPram from "../components/Prices/PricesPram";
import PricesCarpets from "../components/Prices/PricesCarpets";
import PricesFurniture from "../components/Prices/PricesFurniture";
import PricesWindows from "../components/Prices/PricesWindows";
import PricesDisinfection from "../components/Prices/PricesDisinfection";
import PricesAdditional from "../components/Prices/PricesAdditional";
import PricesBusiness from "../components/Prices/PricesBusiness";
import PricesBabyCleaning from "../components/Prices/PricesBabyCleaning";
import PricesGeneralCleaning from "../components/Prices/PricesGeneralCleaning";
import PricesBuildingCleaning from "../components/Prices/PricesBuildingCleaning";
import PricesIllnessCleaning from "../components/Prices/PricesIllnessCleaning";
import { Media } from "../styles/MediaQueries";

const Pricing = () => {
  return (
    <>
      <Container>
        <PriceAccordion type="single" collapsible>
          <Accordion.Item value="item-1">
            <StyledHeader>
              <AccordionStyledTrigger heading="Уборки" />
            </StyledHeader>
            <StyledContent>
              <HeadingTertiaryBold>Генеральная уборка</HeadingTertiaryBold>
              <PricesGeneralCleaning />
              <HeadingTertiaryBold>Уборка перед рождением ребенка</HeadingTertiaryBold>
              <PricesBabyCleaning />
              <HeadingTertiaryBold>Уборка после болезни/смерти человека</HeadingTertiaryBold>
              <PricesIllnessCleaning />
              <HeadingTertiaryBold>Уборка после ремонта</HeadingTertiaryBold>
              <PricesBuildingCleaning />
            </StyledContent>
          </Accordion.Item>

          <Accordion.Item value="item-2">
            <StyledHeader>
              <AccordionStyledTrigger heading="Химчистка" />
            </StyledHeader>
            <StyledContent>
              <HeadingTertiaryBold>Химчистка одежды</HeadingTertiaryBold>
              <PricesClothes />
              <HeadingTertiaryBold>Химчистка мягких игрушек</HeadingTertiaryBold>
              <PricesToys />
              <HeadingTertiaryBold>Химчистка аксессуаров</HeadingTertiaryBold>
              <PricesAccessories />
              <HeadingTertiaryBold>Химчистка текстиля</HeadingTertiaryBold>
              <PricesTextile />
              <HeadingTertiaryBold>Химчистка колясок</HeadingTertiaryBold>
              <PricesPram />
            </StyledContent>
          </Accordion.Item>

          <Accordion.Item value="item-3">
            <StyledHeader>
              <AccordionStyledTrigger heading="Чистка ковров" />
            </StyledHeader>
            <StyledContent>
              <PricesCarpets />
            </StyledContent>
          </Accordion.Item>

          <Accordion.Item value="item-4">
            <StyledHeader>
              <AccordionStyledTrigger heading="Чистка мебели" />
            </StyledHeader>
            <StyledContent>
              <PricesFurniture />
            </StyledContent>
          </Accordion.Item>

          <Accordion.Item value="item-5">
            <StyledHeader>
              <AccordionStyledTrigger heading="Мойка окон и балконов" />
            </StyledHeader>
            <StyledContent>
              <PricesWindows />
            </StyledContent>
          </Accordion.Item>

          <Accordion.Item value="item-6">
            <StyledHeader>
              <AccordionStyledTrigger heading="Дезинфекция" />
            </StyledHeader>
            <StyledContent>
              <PricesDisinfection />
            </StyledContent>
          </Accordion.Item>

          <Accordion.Item value="item-7">
            <StyledHeader>
              <AccordionStyledTrigger heading="Дополнительные услуги" />
            </StyledHeader>
            <StyledContent>
              <PricesAdditional />
            </StyledContent>
          </Accordion.Item>

          <Accordion.Item value="item-8">
            <StyledHeader>
              <AccordionStyledTrigger heading="Услуги для бизнеса" />
            </StyledHeader>
            <StyledContent>
              <PricesBusiness />
            </StyledContent>
          </Accordion.Item>
        </PriceAccordion>
      </Container>
    </>
  );
};

const AccordionStyledTrigger = ({ heading }) => {
  return (
    <StyledTrigger>
      <span>{heading}</span>
      <AccordionArrow>
        <img src={arrow} alt="Открыть список" />
      </AccordionArrow>
    </StyledTrigger>
  );
};

export default Pricing;

const Container = styled.div`
  max-width: 1280px;
  display: flex;
  padding: 0 24px;
  margin: 32px auto;
  width: 100%;
`;

const PriceAccordion = styled(Accordion.Root)`
  width: 100%;
  flex: 1;
`;

const StyledHeader = styled(Accordion.Header)`
  width: 100%;
`;

const StyledTrigger = styled(Accordion.AccordionTrigger)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 24px;
  font-style: normal;
  border: none;
  border-radius: 4px;
  background-color: ${Colors.LightGrey};
  text-align: left;
  font-size: 20px;
  line-height: 1.5;

  & > span {
    color: ${Colors.PrimaryDark};
  }

  &[data-state="open"] {
    font-weight: 500;
    background-color: ${Colors.SecondaryLight};
  }

  &[data-state="open"] > div {
    transform: translateY(100%) rotate(180deg);
  }
`;

const StyledContent = styled(Accordion.AccordionContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  &[data-state="open"] {
    margin-bottom: 32px;

    ${Media.m} {
      margin-bottom: 16px;
    }
  }

  ${Media.m} {
    margin-top: 24px;
  }
`;

const AccordionArrow = styled.div`
  width: 24px;
  height: 24px;
  transform: rotate(0);
  transform-origin: top;
`;
