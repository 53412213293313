import { useRef } from "react";
import MobileMenu from "./MobileMenu";
import { MenuButton } from "./Buttons/MenuButton";
import styled from "styled-components";
import { useClickOutside } from "../hooks/useClickOutside";

const NavigationMobile = ({ open, setOpen }) => {
  const ref = useRef(null);
  useClickOutside(ref, () => setOpen(false));

  return (
    <Navigation ref={ref}>
      <MenuButton open={open} setOpen={setOpen} />
      <MobileMenu open={open} />
    </Navigation>
  );
};

const Navigation = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
`;

export default NavigationMobile;
