import React from "react";
import { ServiceTable, ServiceTh, ServiceTd, ServiceCaption } from "../ServicePageStyle";

const PricesAccessories = () => {
  return (
    <>
      <ServiceTable>
        <ServiceCaption>Чемоданы, дорожные сумки, кейсы</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Услуга</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Химчистка изделий из ткани</ServiceTd>
            <ServiceTd>от 420 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Химчистка изделий из кожи/экокожи</ServiceTd>
            <ServiceTd>от 1220 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Химчистка внутренней подкладки</ServiceTd>
            <ServiceTd>от 470 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Пропитка кожаных кейсов и сумок защитным составом</ServiceTd>
            <ServiceTd>от 920 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>
      <ServiceTable>
        <ServiceCaption>Авточехлы</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Вид</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Автокресло</ServiceTd>
            <ServiceTd>от 470 руб./м2</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Автокресло блок</ServiceTd>
            <ServiceTd>350 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Авточехол одиночный</ServiceTd>
            <ServiceTd>от 370 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Авточехол двойной</ServiceTd>
            <ServiceTd>от 720 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>

      <ServiceTable>
        <ServiceCaption>Головные уборы</ServiceCaption>
        <thead>
          <tr>
            <ServiceTh>Вид</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Кепка</ServiceTd>
            <ServiceTd>от 200 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Шапка (текстиль)</ServiceTd>
            <ServiceTd>от 250 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Удаление катышков</ServiceTd>
            <ServiceTd>от 250 руб.</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>
    </>
  );
};

export default PricesAccessories;
