import React from "react";
import styled from "styled-components";
import Map from "../components/Map";
import { HeadingTertiaryBold } from "../styles/TextStyle";
import location from "../assets/location.svg";
import call from "../assets/call.svg";
import clock from "../assets/clock.svg";
import mail from "../assets/mail.svg";
import { Colors } from "../styles/Styles";
import { Media } from "../styles/MediaQueries";

const Contacts = () => {
  return (
    <>
      <Container>
        <Info>
          <div>
            <HeadingTertiaryBold>Наши пункты приема:</HeadingTertiaryBold>
            <ContactsBox>
              <ContactOffice address="ул. Киевская 28Б" phone="8-913-860-03-05" weekdays="10:00 до 17:00" />
            </ContactsBox>
          </div>
          <ContactsDirectBox>
            <ContactEmail email="chisto.taym.19@mail.ru" description="общий адрес" />
            <ContactEmail email="corp-chistotaim43258@yandex.ru " description="интернет-магазин" />
          </ContactsDirectBox>
        </Info>
        <Map />
      </Container>
    </>
  );
};

const ContactOffice = ({ address, phone, weekdays }) => {
  return (
    <Address>
      <AddressRow>
        <ContactIcon src={location} alt="адрес" /> <span>{address}</span>
      </AddressRow>
      <ContactPhone phone={phone} />
      <AddressRow>
        <ContactIcon src={clock} alt="адрес" />
        Часы работы
      </AddressRow>
      <p>пн - пт с {weekdays}</p>
      <p>сб с 10:00 до 15:00</p>
      <p>вс - выходной</p>
    </Address>
  );
};

const ContactEmail = ({ email, description }) => {
  return (
    <AddressRow>
      <ContactLink href={`mailto:${email}`}>
        <ContactIcon src={mail} alt="" />
        {email}
      </ContactLink>
      - {description}
    </AddressRow>
  );
};

const ContactPhone = ({ phone }) => {
  return (
    <ContactLink href={`tel:${phone}`}>
      <ContactIcon src={call} alt="телефон" />
      {phone}
    </ContactLink>
  );
};

export default Contacts;

const Container = styled.div`
  max-width: 1280px;
  display: flex;
  column-gap: 24px;
  padding: 0 24px;
  margin: 32px auto;

  ${Media.l} {
    flex-direction: column-reverse;
    row-gap: 24px;
    margin: 16px auto;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;

  ${Media.l} {
    flex-direction: row;
    justify-content: space-evenly;
    column-gap: 24px;
    width: 100%;
  }

  ${Media.m} {
    flex-direction: column;
    padding: 16px 0;
  }
`;

const ContactsBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 16px;

  ${Media.l} {
    flex-direction: row;
    column-gap: 16px;
    width: 100%;
  }
`;

const ContactsDirectBox = styled(ContactsBox)`
  ${Media.l} {
    flex-direction: column;
    column-gap: 16px;
    width: min-content;
  }

  ${Media.m} {
    width: 100%;
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  ${Media.m} {
    font-size: 14px;
  }
`;

const AddressRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 8px;

  & > span {
    font-weight: 600;
    color: ${Colors.PrimaryDark};
    word-break: break-word;
  }

  ${Media.m} {
    font-size: 14px;
  }
`;

const ContactLink = styled.a`
  display: flex;
  align-items: center;
  color: ${Colors.PrimaryDark};
  column-gap: 8px;
  white-space: nowrap;

  ${Media.m} {
    font-size: 14px;
  }
`;

const ContactIcon = styled.img`
  width: 16px;
  height: 16px;

  ${Media.m} {
    width: 12px;
    height: 12px;
  }
`;
