import * as Dialog from "@radix-ui/react-dialog";
import styled from "styled-components";
import { Colors } from "../styles/Styles";
import FormOrder from "./Forms/FormOrder";
import { Media } from "../styles/MediaQueries";

export const Popup = () => {
  return (
    <DialogWrapper>
      <Trigger>Заказать услугу</Trigger>
      <Dialog.Portal>
        <Overlay>
          <Content>
            <FormOrder />
          </Content>
        </Overlay>
      </Dialog.Portal>
    </DialogWrapper>
  );
};

export default Popup;

const DialogWrapper = Dialog.Root;

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 1000;
`;

const Content = styled(Dialog.Content)`
  width: 548px;
  height: 550px;
  padding: 16px;
  background: white;
  border-radius: 4px;

  ${Media.m} {
    width: calc(100% - 32px);
    margin: 16px;
  }
`;

const Trigger = styled(Dialog.Trigger)`
  align-self: center;
  border-radius: 8px;
  padding: 12px;
  font-size: 18px;
  font-weight: 500;
  background-color: ${Colors.Secondary};
  color: ${Colors.White};

  ${Media.m} {
    font-size: 16px;
    padding: 10px;
  }

  &:hover {
    background-color: ${Colors.SecondaryDark};
  }
`;
