import React from "react";
import styled from "styled-components";
import Navigation from "./Navigation";
import { Colors } from "../styles/Styles";
import { bool } from "prop-types";

const MobileMenu = ({ open }) => {
  return (
    <Menu open={open}>
      <Navigation />
    </Menu>
  );
};

export default MobileMenu;

MobileMenu.propTypes = {
  open: bool.isRequired,
};

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 32px;
  width: 320px;
  height: ${({ open }) => (open ? "100%" : "40px")};
  background-color: ${Colors.White};
  border-radius: 4px;
  padding: 50px 40px 32px 32px;
  box-shadow: -10px 16px 36px -19px rgba(0, 134, 195, 1);
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
`;
