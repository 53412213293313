import React from "react";
import { HeadingPrimaryBold, HeadingTertiaryBold } from "../../styles/TextStyle";
import { ServiceText, ServiceList } from "../../components/ServicePageStyle";
import { ServicePageContainer } from "../../components/Layout";
import PricesPram from "../../components/Prices/PricesPram";

const LaundryPram = () => {
  return (
    <>
      <ServicePageContainer>
        <HeadingPrimaryBold>Химчистка колясок, детских аксессуаров, автокресел</HeadingPrimaryBold>
        <ServiceText>
          Химчистка колясок и автомобильных кресел позволяет устранить самые сложные пятна, восстановить внешний вид детских изделий, а порой даже подарить им вторую жизнь. Самостоятельная чистка в домашних условиях не способна решить проблему
          стойких загрязнений, чистоты несъемных деталей и внутренней стороны обивки, где также скапливаются болезнетворные микробы.
        </ServiceText>
        <ServiceText>
          Процедура химчистки детских колясок – не только возможность вернуть изделию внешнюю привлекательность, но и способ поддержания гигиенической чистоты, к которому стоит прибегать для обеспечения здоровья малыша. В компании "Чисто-Тайм"
          химчистка детских автокресел, колясок и других предметов производится специализированными сертифицированными средствами, гарантирующими высокое качество отчистки, их безопасность и гипоаллергенные свойства.
        </ServiceText>
        <HeadingTertiaryBold>Предлагаем быстро, качественно и недорого произвести чистку:</HeadingTertiaryBold>
        <ServiceList>
          <li>автомобильных кресел</li>
          <li>колясок с жестким каркасом</li>
          <li>прогулочных колясок</li>
        </ServiceList>

        <PricesPram />
      </ServicePageContainer>
    </>
  );
};

export default LaundryPram;
