import React from "react";
import { HeadingPrimaryBold, HeadingTertiaryBold } from "../../styles/TextStyle";
import { ServiceList, ServiceText, ServiceTextHighlight } from "../../components/ServicePageStyle";
import { Popup } from "../../components/Popup";
import { ServicePageContainer } from "../../components/Layout";
import PricesBabyCleaning from "../../components/Prices/PricesBabyCleaning";

const BabyCleaning = () => {
  return (
    <>
      <ServicePageContainer>
        <HeadingPrimaryBold>Уборка перед рождением ребенка</HeadingPrimaryBold>
        <HeadingTertiaryBold>Наши рекомендации по уборке перед рождением ребенка</HeadingTertiaryBold>
        <ServiceList>
          <li>профессиональная чистка ковров и штор</li>
          <li>химчистка всех покрывал, одеял и подушек</li>
          <li>уборка всего дома, которая включает в себя обработку всех поверхностей в доме, стен, потолка, труднодоступных антресолей и полок</li>
          <li>Очистка воздуховодов и кондиционеров</li>
        </ServiceList>
        <ServiceText>
          Все поверхности мы обрабатываем специальными экологичными средствами американской премиальной марки ECOLAB, которые не имеют запаха и в дальнейшем препятствуют оседанию пыли. При такой уборке предпочтение отдается очистке паром и применению
          самых деликатных, экологических и гипоаллергенных средств.
        </ServiceText>
        <ServiceText>Уборка в труднодоступных местах проводится с помощью парогенератора. После генеральной уборки в ближайшие два-три месяца будет достаточно проводить поддерживающую уборку.</ServiceText>
        <ServiceText>По вашему запросу всю поверхность квартиры можно обеззаразить профессиональным озонатором. Эта процедура уничтожает 99% спор плесени, бактерий и пыли.</ServiceText>
        <ServiceTextHighlight> При заказе услуги 30% скидки на химчистку ковров и мягкой мебели!</ServiceTextHighlight>
        <PricesBabyCleaning />
        <Popup />
      </ServicePageContainer>
    </>
  );
};

export default BabyCleaning;
