import React from "react";
import { ServiceTable, ServiceTh, ServiceTd } from "../ServicePageStyle";

const PricesIllnessCleaning = () => {
  return (
    <>
      <ServiceTable>
        <thead>
          <tr>
            <ServiceTh>Услуга</ServiceTh>
            <ServiceTh>Стоимость</ServiceTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ServiceTd>Генеральная уборка</ServiceTd>
            <ServiceTd>от 88 руб./м2</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Влажная дезинфекция</ServiceTd>
            <ServiceTd>от 150 руб./м2</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Озонирование и удаление запахов (площадь до 12 м2)</ServiceTd>
            <ServiceTd>1200 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Озонирование и удаление запахов (площадь до 24 м2)</ServiceTd>
            <ServiceTd>2400 руб.</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Озонирование и удаление запахов (площадь более 24 м2)</ServiceTd>
            <ServiceTd>Договорная, после оценки менеджера</ServiceTd>
          </tr>
          <tr>
            <ServiceTd>Сборка и вывоз мусора</ServiceTd>
            <ServiceTd>Договорная, после оценки менеджера</ServiceTd>
          </tr>
        </tbody>
      </ServiceTable>
    </>
  );
};

export default PricesIllnessCleaning;
