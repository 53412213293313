import React from "react";
import baby from "../assets/baby.svg";
import broom from "../assets/broom.svg";
import cleaning from "../assets/cleaning.svg";
import building from "../assets/building.svg";
import { Services, Category } from "./Service";

const ServiceCleaning = () => {
  return (
    <Services>
      <Category icon={cleaning} name="Генеральная" link={"/general"} />
      <Category icon={baby} name="перед рождением ребенка" link={"/baby"} />
      <Category icon={broom} name="после болезни/смерти человека" link={"/ill"} />
      <Category icon={building} name="после ремонта" link={"/building"} />
    </Services>
  );
};

export default ServiceCleaning;
